import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from 'src/app/environments/environment';
import { ApiResponseResults } from '../models/ApiResults/ApiResponseResults';
import { Role } from '../models/users/Role';
import { User } from '../models/users/User';
import { UserTokens } from '../models/users/UserTokens';
import { UserLogin } from '../request/users/UserLogin';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isTblLoading = true;

  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public currentUserTokenSubject: BehaviorSubject<UserTokens>;
  public currentUserToken: Observable<UserTokens>;
  userSuccessChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser") || '{}')
    );
    this.currentUserTokenSubject = new BehaviorSubject<UserTokens>(
      JSON.parse(localStorage.getItem("currentUserToken") || '{}')
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentUserToken = this.currentUserTokenSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get currentUserTokenValue(): UserTokens {
    return this.currentUserTokenSubject.value;
  }

  login(userlogin: UserLogin) {
    return this.http
      .post<any>(`${environment.apiUrl}/login`, userlogin)
      .pipe(
        map((token) => {
          localStorage.setItem("userToken", JSON.stringify(token));
          this.currentUserTokenSubject.next(token);
          return token;
        })
      );
  }

  resetPassword(tokens:string,Password:string,RepeatedPassword:string) {
    let resetPassword = {password:Password,repeatedPassword:RepeatedPassword};
    this.isTblLoading = true;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokens}`
      });

    this.http.post<ApiResponseResults>(`${environment.apiUrl}/reset-password`,resetPassword,{headers}).subscribe(
      (data) => {
        this.isTblLoading = false;
        this.userSuccessChange.next(data.success);
      },
      (error: HttpErrorResponse) => {
        this.isTblLoading = false;
        console.log(error.name + " " + error.message);
      }
    );
  }

  forgotPassword(email:string) {
    let forgotPassword = {email:email};
    this.isTblLoading = true;

    this.http.post<ApiResponseResults>(`${environment.apiUrl}/forgot-password`,forgotPassword).subscribe(
      (data) => {
        this.isTblLoading = false;
        this.userSuccessChange.next(data.success);
      },
      (error: HttpErrorResponse) => {
        this.isTblLoading = false;
        console.log(error.name + " " + error.message);
      }
    );
  }

  private handleError(err: any) {
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      errorMessage = `Ha Ocurrido un Error: ${err.error.message}`;
    } else {
      errorMessage = `Backend codigo de retorno ${err.status}: ${err.body.error}`;
    }
    console.error(err);
    return throwError(errorMessage);
  }

  logout() {
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(new User());
    return of({ success: false });
  }
}

import { Client } from './../../../admin/clients/models/Client';
import { Role } from "./Role";

export class User {
  id: number=0;
  img:string='';
  username: string='';
  password: string='';
  firstName: string='';
  lastName: string='';
  role:Role = Role.Root;
  claims:Array<number> =new Array<number>();
  client:Client = new Client(null);
  token: string='';
}

export const environment = {
  production: true,
  //apiUrl: "https://localhost:7101",
  apiUrl: "https://api360.dosespacios.com",
  mensajeError:"No tiene permiso para realizar esta operación",
  ClientPageSize: 25,
  ClientProjectsPageSize: 25,
  ProjectsPageSize: 25,
  RolesPageSize: 25,
  ClientRolesPageSize: 25,
  ProjectRolesPageSize: 25,
  UsersPageSize: 25,
  ClientUsersPageSize: 25,
  ProjectsUsersPageSize: 25,
  UserProjectsPageSize:  25
};

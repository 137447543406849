export class Client {
  id:Number;
  name: string;
  phone: string;
  cifNif: string;
  address: string;
  cp: number;
  email:string;
  constructor(client:Client | null) {
    {
      this.id = client?.id || 0;
      this.name = client?.name || "";
      this.phone = client?.phone || "";
      this.cifNif = client?.cifNif || "";
      this.address = client?.address || "";
      this.cp = client?.cp || 0;
      this.email = client?.email || "";
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-feather-icons',
  templateUrl: './feather-icons.component.html'
})
export class FeatherIconsComponent implements OnInit {
  @Input("icon") public icon: any;
  @Input("class") public class: any;
  constructor() {}

  ngOnInit(): void {}
}
